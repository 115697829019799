<script>
import { layoutMethods } from "@/state/helpers";

export default {
  data() {
    return {
      help_url: process.env.VUE_APP_HELP_URL,
    };
  },
  mounted() {
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3.parentElement;
        if (parent4) parent4.classList.add("active");
        const parent5 = parent4.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }
  },
  methods: {
    ...layoutMethods,
    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
  },
};
</script>
<template>
  <div class="topnav row">
    <div class="col-xl-10">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
        <div class="collapse navbar-collapse active" id="topnav-menu-content">
          <ul class="navbar-nav" style="width:100%;">
            <li class="nav-item">
              <router-link :to="{ name: 'dashboard' }" class="nav-link"
                ><i class="fas fa-chart-area" aria-hidden="true"></i>Vue
                360°</router-link
              >
            </li>

            <li class="nav-item dropdown">
              <router-link
                :to="canAny([
                    'create_mr',
                    'edit_mr',
                    'delete_mr',
                    'view_mr',
                    'list_mr',
                    'create_vsr',
                    'edit_vsr',
                    'delete_vsr',
                    'view_vsr',
                    'list_vsr',
                    'create_tamr',
                    'edit_tamr',
                    'delete_tamr',
                    'view_tamr',
                    'list_tamr',
                    'create_dr',
                    'edit_dr',
                    'delete_dr',
                    'view_dr',
                    'list_dr',
                    'create_maintenance_order',
                    'edit_maintenance_order',
                    'delete_maintenance_order',
                    'view_maintenance_order',
                    'list_maintenance_order',
                    'create_hosting_booking',
                    'update_hosting_booking',
                    'read_hosting_booking',
                    'delete_hosting_booking',
                    'list_hosting_booking',
                    'isp_acces_log',
                    'isp_acces_log_approve_entry',
                    'isp_acces_log_approve_exit',
                  ]) ? { name: 'service_catalog' } : ''"
                id="topnav-pages"
                class="nav-link dropdown-toggle arrow-none"
                href="javascript:void(0)"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :class="{
                  'disabled-link': !canAny([
                    'create_mr',
                    'edit_mr',
                    'delete_mr',
                    'view_mr',
                    'list_mr',
                    'create_vsr',
                    'edit_vsr',
                    'delete_vsr',
                    'view_vsr',
                    'list_vsr',
                    'create_tamr',
                    'edit_tamr',
                    'delete_tamr',
                    'view_tamr',
                    'list_tamr',
                    'create_dr',
                    'edit_dr',
                    'delete_dr',
                    'view_dr',
                    'list_dr',
                    'create_maintenance_order',
                    'edit_maintenance_order',
                    'delete_maintenance_order',
                    'view_maintenance_order',
                    'list_maintenance_order',
                    'create_hosting_booking',
                    'update_hosting_booking',
                    'read_hosting_booking',
                    'delete_hosting_booking',
                    'list_hosting_booking',
                    'isp_acces_log',
                    'isp_acces_log_approve_entry',
                    'isp_acces_log_approve_exit',
                  ]),
                }"
                @click="onMenuClick"
                ><i class="fas fa-chalkboard mr-2"></i>Catalogue des services
                <div class="arrow-down"></div
              ></router-link>
              <div class="dropdown-menu" aria-labelledby="topnav-pages">
                <router-link
                  :to="canAny([
                      'isp_acces_log',
                      'isp_acces_log_approve_entry',
                      'isp_acces_log_approve_exit',
                    ]) ? { name: 'service_catalog.access' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'isp_acces_log',
                      'isp_acces_log_approve_entry',
                      'isp_acces_log_approve_exit',
                    ]),
                  }"
                  >Gestion d'accès</router-link
                >
                <router-link
                  :to="canAny([
                      'create_mr',
                      'edit_mr',
                      'delete_mr',
                      'view_mr',
                      'list_mr',
                      'create_vsr',
                      'edit_vsr',
                      'delete_vsr',
                      'view_vsr',
                      'list_vsr',
                      'create_tamr',
                      'edit_tamr',
                      'delete_tamr',
                      'view_tamr',
                      'list_tamr',
                      'create_dr',
                      'edit_dr',
                      'delete_dr',
                      'view_dr',
                      'list_dr',
                    ]) ? { name: 'requests' } : ''"
                  :class="{
                    'disabled-link': !canAny([
                      'create_mr',
                      'edit_mr',
                      'delete_mr',
                      'view_mr',
                      'list_mr',
                      'create_vsr',
                      'edit_vsr',
                      'delete_vsr',
                      'view_vsr',
                      'list_vsr',
                      'create_tamr',
                      'edit_tamr',
                      'delete_tamr',
                      'view_tamr',
                      'list_tamr',
                      'create_dr',
                      'edit_dr',
                      'delete_dr',
                      'view_dr',
                      'list_dr',
                    ]),
                  }"
                  class="dropdown-item dropdown-toggle arrow-none"
                  >Requêtes</router-link
                >
                <router-link
                  :to="canAny([
                      'create_rest_ods',
                      'edit_rest_ods',
                      'delete_rest_ods',
                      'view_rest_ods',
                      'list_rest_ods',
                    ]) ? { name: 'service_catalog.restauration' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_rest_ods',
                      'edit_rest_ods',
                      'delete_rest_ods',
                      'view_rest_ods',
                      'list_rest_ods',
                    ]),
                  }"
                  >Restauration</router-link
                >
                <router-link
                  :to="canAny([
                      'create_host_ods', 
                      'edit_host_ods', 
                      'delete_host_ods', 
                      'view_host_ods', 
                      'list_host_ods', 
                      'create_hosting_booking', 
                      'update_hosting_booking', 
                      'read_hosting_booking', 
                      'delete_hosting_booking', 
                      'list_hosting_booking', 
                      'approve_booking', 
                      'reject_booking', 
                      'checkin_booking', 
                      'checkout_booking'
                    ]) ? { name: 'ops.hosting.index' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_host_ods', 
                      'edit_host_ods', 
                      'delete_host_ods', 
                      'view_host_ods', 
                      'list_host_ods', 
                      'create_hosting_booking', 
                      'update_hosting_booking', 
                      'read_hosting_booking', 
                      'delete_hosting_booking', 
                      'list_hosting_booking', 
                      'approve_booking', 
                      'reject_booking', 
                      'checkin_booking', 
                      'checkout_booking'
                    ]),
                  }"
                  >Hébergement</router-link
                >
                <router-link
                  :to="canAny([
                      'create_threedops_ods',
                      'edit_threedops_ods',
                      'delete_threedops_ods',
                      'view_threedops_ods',
                      'list_threedops_ods',
                    ]) ? { name: 'service_catalog.operations' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_threedops_ods',
                      'edit_threedops_ods',
                      'delete_threedops_ods',
                      'view_threedops_ods',
                      'list_threedops_ods',
                    ]),
                  }"
                  >Opérations 3D</router-link
                >
                <router-link
                  :to="canAny([
                      'create_gs_ods',
                      'edit_gs_ods',
                      'delete_gs_ods',
                      'view_gs_ods',
                      'list_gs_ods',
                    ]) ? { name: 'service_catalog.green_areas'} : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_gs_ods',
                      'edit_gs_ods',
                      'delete_gs_ods',
                      'view_gs_ods',
                      'list_gs_ods',
                    ]),
                  }"
                  >Espaces Verts</router-link
                >
                <router-link
                  :to="canAny([
                      'create_mr',
                      'edit_mr',
                      'delete_mr',
                      'view_mr',
                      'list_mr',
                    ]) ? { name: 'requests.maintenance.index' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_mr',
                      'edit_mr',
                      'delete_mr',
                      'view_mr',
                      'list_mr',
                    ]),
                  }"
                  >Maintenance des Installations</router-link
                >
                <router-link
                  :to="canAny([
                      'create_stock_entry',
                      'edit_stock_entry',
                      'delete_stock_entry',
                      'view_stock_entry',
                      'list_stock_entry',
                      'create_stock_exit',
                      'edit_stock_exit',
                      'delete_stock_exit',
                      'view_stock_exit',
                      'list_stock_exit',
                    ]) ? { name: 'service_catalog.stock' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_stock_entry',
                      'edit_stock_entry',
                      'delete_stock_entry',
                      'view_stock_entry',
                      'list_stock_entry',
                      'create_stock_exit',
                      'edit_stock_exit',
                      'delete_stock_exit',
                      'view_stock_exit',
                      'list_stock_exit',
                    ]),
                  }"
                  >Opérations Stock</router-link
                >
                <router-link
                  :to="canAny([
                      'create_control',
                      'edit_control',
                      'delete_control',
                      'view_control',
                      'list_control',
                    ])  ? { name: 'service_catalog.hse' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_control',
                      'edit_control',
                      'delete_control',
                      'view_control',
                      'list_control',
                    ]),
                  }"
                  >HSE</router-link
                >

                <router-link
                  :to="canAny([
                      'create_custom_ods',
                      'edit_custom_ods',
                      'delete_custom_ods',
                      'view_custom_ods',
                      'list_custom_ods',
                    ]) ? { name: 'service_catalog.custom_services' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_custom_ods',
                      'edit_custom_ods',
                      'delete_custom_ods',
                      'view_custom_ods',
                      'list_custom_ods',
                    ]),
                  }"
                  >Autres Services</router-link
                >

                <router-link
                  :to="canAny([
                      'personal_transp_log',
                      'personal_transp_log_approve_entry',
                      'personal_transp_log_approve_exit',
                    ]) ? { name: 'service_catalog.personels_transport' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'personal_transp_log',
                      'personal_transp_log_approve_entry',
                      'personal_transp_log_approve_exit',
                    ]),
                  }"
                  >Transport du Personnel</router-link
                >
                <a
                  href="javascript:void(0)"
                  class="dropdown-item dropdown-toggle arrow-none disabled-link"
                  >Entretien & Nettoyage</a
                >
                <a
                  href="javascript:void(0)"
                  class="dropdown-item dropdown-toggle arrow-none disabled-link"
                  >Blanchisserie & Dégraissage</a
                >
                <!-- <a href="javascript:void(0)" class="dropdown-item dropdown-toggle arrow-none disabled-link">Transport du Personnel</a> -->
                <a
                  href="javascript:void(0)"
                  class="dropdown-item dropdown-toggle arrow-none disabled-link"
                  >Transport de Marchandise</a
                >
                <a
                  href="javascript:void(0)"
                  class="dropdown-item dropdown-toggle arrow-none disabled-link"
                  >Autres Prestations & Fournitures diverses</a
                >
              </div>
            </li>

            <li class="nav-item dropdown">
              <router-link
                :to="canAny([
                    'db_view_contract_dashboard',
                    'create_inv_att',
                    'edit_inv_att',
                    'delete_inv_att',
                    'view_inv_att',
                    'list_inv_att',
                    'create_supp_inv',
                    'edit_supp_inv',
                    'delete_supp_inv',
                    'view_supp_inv',
                    'list_supp_inv',
                    'create_supp_inv_pay',
                    'edit_supp_inv_pay',
                    'delete_supp_inv_pay',
                    'view_supp_inv_pay',
                    'list_supp_inv_pay',
                  ]) ? { name: 'monitoring_and_management' }: ''"
                id="topnav-pages"
                class="nav-link dropdown-toggle arrow-none"
                href="javascript:void(0)"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="onMenuClick"
                :class="{
                  'disabled-link': !canAny([
                    'db_view_contract_dashboard',
                    'create_inv_att',
                    'edit_inv_att',
                    'delete_inv_att',
                    'view_inv_att',
                    'list_inv_att',
                    'create_supp_inv',
                    'edit_supp_inv',
                    'delete_supp_inv',
                    'view_supp_inv',
                    'list_supp_inv',
                    'create_supp_inv_pay',
                    'edit_supp_inv_pay',
                    'delete_supp_inv_pay',
                    'view_supp_inv_pay',
                    'list_supp_inv_pay',
                  ]),
                }"
              >
                <i class="fas fa-check-double mr-2"></i>Suivi & Gestion
                <div class="arrow-down"></div
              ></router-link>
              <div class="dropdown-menu" aria-labelledby="topnav-pages">
                <router-link
                  :to="$can('db_view_contract_dashboard') ? { name: 'monitoring_and_management.contract' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !$can('db_view_contract_dashboard'),
                  }"
                  >Suivi des contrats</router-link
                >
                <router-link
                  :to="canAny([
                      'create_inv_att',
                      'edit_inv_att',
                      'delete_inv_att',
                      'view_inv_att',
                      'list_inv_att',
                    ]) ? { name: 'invoicing' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_inv_att',
                      'edit_inv_att',
                      'delete_inv_att',
                      'view_inv_att',
                      'list_inv_att',
                    ]),
                  }"
                  >Ordonnancement</router-link
                >
                <router-link
                  :to="canAny([
                      'create_supp_inv',
                      'edit_supp_inv',
                      'delete_supp_inv',
                      'view_supp_inv',
                      'list_supp_inv',
                      'create_supp_inv_pay',
                      'edit_supp_inv_pay',
                      'delete_supp_inv_pay',
                      'view_supp_inv_pay',
                      'list_supp_inv_pay',
                    ]) ? { name: 'monitoring_and_management.suppliers_invoices' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_supp_inv',
                      'edit_supp_inv',
                      'delete_supp_inv',
                      'view_supp_inv',
                      'list_supp_inv',
                      'create_supp_inv_pay',
                      'edit_supp_inv_pay',
                      'delete_supp_inv_pay',
                      'view_supp_inv_pay',
                      'list_supp_inv_pay',
                    ]),
                  }"
                  >Suivi de Facturation</router-link
                >
                <a
                  href="javascript:void(0)"
                  class="dropdown-item dropdown-toggle arrow-none disabled-link"
                  >Gestion du Parc roulant</a
                >
                <a
                  href="javascript:void(0)"
                  class="dropdown-item dropdown-toggle arrow-none disabled-link"
                  >Service Transport Personnel</a
                >
                <a
                  href="javascript:void(0)"
                  class="dropdown-item dropdown-toggle arrow-none disabled-link"
                  >Service Aérien</a
                >
              </div>
            </li>

            <li class="nav-item dropdown">
              <router-link
                :to="canAny([
                    'create_hierarchie',
                    'update_hierarchie',
                    'read_hierarchie',
                    'delete_hierarchie',
                    'list_hierarchie',
                    'create_camp',
                    'update_camp',
                    'read_camp',
                    'delete_camp',
                    'list_camp',
                    'create_infra',
                    'update_infra',
                    'read_infra',
                    'delete_infra',
                    'list_infra',
                    'create_hosting_room',
                    'update_hosting_room',
                    'read_hosting_room',
                    'delete_hosting_room',
                    'list_hosting_room',
                    'create_kitchen',
                    'update_kitchen',
                    'read_kitchen',
                    'delete_kitchen',
                    'list_kitchen',
                    'create_supplier',
                    'edit_supplier',
                    'delete_supplier',
                    'view_supplier',
                    'list_supplier',
                    'create_contract',
                    'edit_contract',
                    'delete_contract',
                    'view_contract',
                    'list_contract',
                    'create_employee',
                    'edit_employee',
                    'delete_employee',
                    'view_employee',
                    'list_employee',
                    'create_animal',
                    'edit_animal',
                    'delete_animal',
                    'view_animal',
                    'list_animal',
                    'create_plant',
                    'edit_plant',
                    'delete_plant',
                    'view_plant',
                    'list_plant',
                    'create_aac',
                    'edit_aac',
                    'delete_aac',
                    'view_aac',
                    'list_aac',
                    'create_visitor',
                    'edit_visitor',
                    'delete_visitor',
                    'view_visitor',
                    'list_visitor',
                    'create_equipment',
                    'edit_equipment',
                    'delete_equipment',
                    'view_equipment',
                    'list_equipment',
                    'create_product',
                    'edit_product',
                    'delete_product',
                    'view_product',
                    'list_product',
                    'create_product_categ',
                    'edit_product_categ',
                    'delete_product_categ',
                    'view_product_categ',
                    'list_product_categ',
                    'create_warehouse',
                    'edit_warehouse',
                    'delete_warehouse',
                    'view_warehouse',
                    'list_warehouse',
                    'create_extc',
                    'edit_extc',
                    'delete_extc',
                    'view_extc',
                    'list_extc',
                  ]) ? { name: 'master_data' } : ''"
                id="topnav-pages"
                class="nav-link dropdown-toggle arrow-none"
                href="javascript:void(0)"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="onMenuClick"
                :class="{
                  'disabled-link': !canAny([
                    'create_hierarchie',
                    'update_hierarchie',
                    'read_hierarchie',
                    'delete_hierarchie',
                    'list_hierarchie',
                    'create_camp',
                    'update_camp',
                    'read_camp',
                    'delete_camp',
                    'list_camp',
                    'create_infra',
                    'update_infra',
                    'read_infra',
                    'delete_infra',
                    'list_infra',
                    'create_hosting_room',
                    'update_hosting_room',
                    'read_hosting_room',
                    'delete_hosting_room',
                    'list_hosting_room',
                    'create_kitchen',
                    'update_kitchen',
                    'read_kitchen',
                    'delete_kitchen',
                    'list_kitchen',
                    'create_supplier',
                    'edit_supplier',
                    'delete_supplier',
                    'view_supplier',
                    'list_supplier',
                    'create_contract',
                    'edit_contract',
                    'delete_contract',
                    'view_contract',
                    'list_contract',
                    'create_employee',
                    'edit_employee',
                    'delete_employee',
                    'view_employee',
                    'list_employee',
                    'create_animal',
                    'edit_animal',
                    'delete_animal',
                    'view_animal',
                    'list_animal',
                    'create_plant',
                    'edit_plant',
                    'delete_plant',
                    'view_plant',
                    'list_plant',
                    'create_aac',
                    'edit_aac',
                    'delete_aac',
                    'view_aac',
                    'list_aac',
                    'create_visitor',
                    'edit_visitor',
                    'delete_visitor',
                    'view_visitor',
                    'list_visitor',
                    'create_equipment',
                    'edit_equipment',
                    'delete_equipment',
                    'view_equipment',
                    'list_equipment',
                    'create_product',
                    'edit_product',
                    'delete_product',
                    'view_product',
                    'list_product',
                    'create_product_categ',
                    'edit_product_categ',
                    'delete_product_categ',
                    'view_product_categ',
                    'list_product_categ',
                    'create_warehouse',
                    'edit_warehouse',
                    'delete_warehouse',
                    'view_warehouse',
                    'list_warehouse',
                    'create_extc',
                    'edit_extc',
                    'delete_extc',
                    'view_extc',
                    'list_extc',
                  ]),
                }"
              >
                <i class="fas fa-database mr-2"></i>Master Data
                <div class="arrow-down"></div
              ></router-link>
              <div class="dropdown-menu" aria-labelledby="topnav-pages">
                <div class="dropdown">
                  <router-link
                    class="dropdown-item dropdown-toggle"
                    :to="canAny([
                    'create_hierarchie',
                    'update_hierarchie',
                    'read_hierarchie',
                    'delete_hierarchie',
                    'list_hierarchie',
                    'create_infra',
                    'update_infra',
                    'read_infra',
                    'delete_infra',
                    'list_infra',
                    'create_hosting_room',
                    'update_hosting_room',
                    'read_hosting_room',
                    'delete_hosting_room',
                    'list_hosting_room',
                    'create_kitchen',
                    'update_kitchen',
                    'read_kitchen',
                    'delete_kitchen',
                    'list_kitchen',
                    'create_supplier',
                    'edit_supplier',
                    'delete_supplier',
                    'view_supplier',
                    'list_supplier',
                    'create_employee',
                    'edit_employee',
                    'delete_employee',
                    'view_employee',
                    'list_employee',
                    'create_animal',
                    'edit_animal',
                    'delete_animal',
                    'view_animal',
                    'list_animal',
                    'create_plant',
                    'edit_plant',
                    'delete_plant',
                    'view_plant',
                    'list_plant',
                    'create_aac',
                    'edit_aac',
                    'delete_aac',
                    'view_aac',
                    'list_aac',
                    'create_visitor',
                    'edit_visitor',
                    'delete_visitor',
                    'view_visitor',
                    'list_visitor',
                    'create_equipment',
                    'edit_equipment',
                    'delete_equipment',
                    'view_equipment',
                    'list_equipment',
                    'create_product',
                    'edit_product',
                    'delete_product',
                    'view_product',
                    'list_product',
                    'create_product_categ',
                    'edit_product_categ',
                    'delete_product_categ',
                    'view_product_categ',
                    'list_product_categ',
                    'create_warehouse',
                    'edit_warehouse',
                    'delete_warehouse',
                    'view_warehouse',
                    'list_warehouse',
                    'create_extc',
                    'edit_extc',
                    'delete_extc',
                    'view_extc',
                    'list_extc',
                  ]) ? { name: 'master_data.referentiels' } : ''"
                    >Référentiels
                    <div class="arrow-down"></div>
                  </router-link>

                  <div class="dropdown-menu">
                    <template>
                      <router-link
                        class="dropdown-item side-nav-link-ref"
                        :to="canAny(['create_hierarchie', 'update_hierarchie', 'read_hierarchie', 'delete_hierarchie', 'list_hierarchie']) ? { name: 'master_data.referentiels.index' } : ''"
                        :class="{
                          'disabled-link': !canAny([
                            'create_hierarchie',
                            'update_hierarchie',
                            'read_hierarchie',
                            'delete_hierarchie',
                            'list_hierarchie',
                          ]),
                        }"
                        >Gestion Des Structures</router-link
                      >
                      <router-link
                        class="dropdown-item side-nav-link-ref"
                        :to="canAny(['create_infra', 'update_infra', 'read_infra', 'delete_infra', 'list_infra']) ? { name: 'master_data.referentiels.infrastructure'} : ''"
                        :class="{
                          'disabled-link': !canAny([
                            'create_infra',
                            'update_infra',
                            'read_infra',
                            'delete_infra',
                            'list_infra',
                          ]),
                        }"
                        >Gestion Des Infrastrutures</router-link
                      >
                      <router-link
                        class="dropdown-item side-nav-link-ref"
                        :to="{ name: 'master_data.referentiels.extreme_business', }"
                        :class="{
                          'disabled-link': !canAny([
                            'create_extc',
                            'edit_extc',
                            'delete_extc',
                            'view_extc',
                            'list_extc',
                            'create_ie',
                            'update_ie',
                            'read_ie',
                            'delete_ie',
                            'list_ie',
                            'create_supplier',
                            'edit_supplier',
                            'delete_supplier',
                            'view_supplier',
                            'list_supplier',
                          ]),
                        }"
                        >Gestion Des Entreprises Externe</router-link
                      >
                      <router-link
                        class="dropdown-item side-nav-link-ref"
                        :to="canAny(['create_employee', 'edit_employee', 'delete_employee', 'view_employee', 'list_employee']) ? { name: 'master_data.referentiels.users' } : ''"
                        :class="{
                          'disabled-link': !canAny([
                            'create_employee',
                            'edit_employee',
                            'delete_employee',
                            'view_employee',
                            'list_employee',
                            'create_visitor',
                            'edit_visitor',
                            'delete_visitor',
                            'view_visitor',
                            'list_visitor',
                          ]),
                        }"
                        >Gestion Du Personnel</router-link
                      >

                      <div class="dropdown">
                        <router-link
                          class="dropdown-item dropdown-toggle"
                          :to="canAny([
                              'create_product',
                              'edit_product',
                              'delete_product',
                              'view_product',
                              'list_product',
                              'create_product_categ',
                              'edit_product_categ',
                              'delete_product_categ',
                              'view_product_categ',
                              'list_product_categ',
                              'create_warehouse',
                              'edit_warehouse',
                              'delete_warehouse',
                              'view_warehouse',
                              'list_warehouse',
                            ]) ? { name: 'master_data.referentiels.stock_management', } : ''"
                          :class="{
                            'disabled-link': !canAny([
                              'create_product',
                              'edit_product',
                              'delete_product',
                              'view_product',
                              'list_product',
                              'create_product_categ',
                              'edit_product_categ',
                              'delete_product_categ',
                              'view_product_categ',
                              'list_product_categ',
                              'create_warehouse',
                              'edit_warehouse',
                              'delete_warehouse',
                              'view_warehouse',
                              'list_warehouse',
                            ]),
                          }"
                        >
                          Gestion de Stock
                          <div class="arrow-down"></div>
                        </router-link>
                        <div class="dropdown-menu">
                          <router-link
                            class="dropdown-item side-nav-link-ref"
                            :to="{ name: 'settings.warehouses.index' }"
                            :class="{
                              'disabled-link': !canAny([
                                'create_warehouse',
                                'edit_warehouse',
                                'delete_warehouse',
                                'view_warehouse',
                                'list_warehouse',
                              ]),
                            }"
                            >Gestion Des Magasins</router-link
                          >
                          <router-link
                            class="dropdown-item side-nav-link-ref"
                            :to="canAny([
                                'create_product_categ',
                                'edit_product_categ',
                                'delete_product_categ',
                                'view_product_categ',
                                'list_product_categ',
                              ]) ? { name: 'settings.product_category.index' } : ''"
                            :class="{
                              'disabled-link': !canAny([
                                'create_product_categ',
                                'edit_product_categ',
                                'delete_product_categ',
                                'view_product_categ',
                                'list_product_categ',
                              ]),
                            }"
                            >Gestion Des catégories de produits</router-link
                          >
                          <router-link
                            class="dropdown-item side-nav-link-ref"
                            :to="canAny([
                                'create_product',
                                'edit_product',
                                'delete_product',
                                'view_product',
                                'list_product',
                              ]) ? { name: 'base.stock.products' } : ''"
                            :class="{
                              'disabled-link': !canAny([
                                'create_product',
                                'edit_product',
                                'delete_product',
                                'view_product',
                                'list_product',
                              ]),
                            }"
                            >Gestion Des produits</router-link
                          >
                        </div>
                      </div>

                      <router-link
                        class="dropdown-item side-nav-link-ref"
                        :to="canAny([
                            'create_animal',
                            'edit_animal',
                            'delete_animal',
                            'view_animal',
                            'list_animal',
                          ]) ? { name: 'master_data.referentiels.zoo.index' } : ''"
                        :class="{
                          'disabled-link': !canAny([
                            'create_animal',
                            'edit_animal',
                            'delete_animal',
                            'view_animal',
                            'list_animal',
                          ]),
                        }"
                        >Gestion Du Parc Animalier</router-link
                      >
                      <router-link
                        class="dropdown-item side-nav-link-ref"
                        :to="canAny([
                            'create_hosting_room',
                            'update_hosting_room',
                            'read_hosting_room',
                            'delete_hosting_room',
                            'list_hosting_room',
                          ]) ? { name: 'base.infrastructures.rooms' } : ''"
                        :class="{
                          'disabled-link': !canAny([
                            'create_hosting_room',
                            'update_hosting_room',
                            'read_hosting_room',
                            'delete_hosting_room',
                            'list_hosting_room',
                          ]),
                        }"
                        >Gestion Des Chambres</router-link
                      >
                      <router-link
                        class="dropdown-item side-nav-link-ref"
                        :to="canAny([
                            'create_kitchen',
                            'update_kitchen',
                            'read_kitchen',
                            'delete_kitchen',
                            'list_kitchen',
                          ]) ? { name: 'base.infrastructures.kitchen' } : ''"
                        :class="{
                          'disabled-link': !canAny([
                            'create_kitchen',
                            'update_kitchen',
                            'read_kitchen',
                            'delete_kitchen',
                            'list_kitchen',
                          ]),
                        }"
                        >Gestion Des Cuisines</router-link
                      >
                      <router-link
                        class="dropdown-item side-nav-link-ref"
                        :to="canAny([
                            'create_equipment',
                            'edit_equipment',
                            'delete_equipment',
                            'view_equipment',
                            'list_equipment',
                          ]) ? { name: 'base.misc.equipments' } : ''"
                        :class="{
                          'disabled-link': !canAny([
                            'create_equipment',
                            'edit_equipment',
                            'delete_equipment',
                            'view_equipment',
                            'list_equipment',
                          ]),
                        }"
                        >Gestion Des Equipments</router-link
                      >
                      <router-link
                        class="dropdown-item side-nav-link-ref"
                        :to="canAny([
                            'create_aac',
                            'edit_aac',
                            'delete_aac',
                            'view_aac',
                            'list_aac',
                          ]) ? { name: 'master_data.referentiels.analytics' } : ''"
                        :class="{
                          'disabled-link': !canAny([
                            'create_aac',
                            'edit_aac',
                            'delete_aac',
                            'view_aac',
                            'list_aac',
                          ]),
                        }"
                        >Gestion Des compte analytics</router-link
                      >
                    </template>
                  </div>
                </div>
                <router-link
                  :to="canAny([
                      'create_camp',
                      'update_camp',
                      'read_camp',
                      'delete_camp',
                      'list_camp',
                    ]) ? { name: 'settings.lifebases' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_camp',
                      'update_camp',
                      'read_camp',
                      'delete_camp',
                      'list_camp',
                    ]),
                  }"
                  >Fiche base de vie</router-link
                >
                <router-link
                  :to="canAny([
                      'create_contract',
                      'edit_contract',
                      'delete_contract',
                      'view_contract',
                      'list_contract',
                    ]) ? { name: 'base.contracts.contracts.index' } : ''"
                  class="dropdown-item dropdown-toggle arrow-none"
                  :class="{
                    'disabled-link': !canAny([
                      'create_contract',
                      'edit_contract',
                      'delete_contract',
                      'view_contract',
                      'list_contract',
                    ]),
                  }"
                  >Fiche contrat</router-link
                >
              </div>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'dashboard' }" class="nav-link"
                ><i class="bx bx-home-circle mr-2"></i>Dashboard &
                rapports</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                :to="canAny(['system_settings_crud']) ? { name: 'base.settings.index' } : ''"
                :class="{ 'disabled-link': !canAny(['system_settings_crud']) }"
                class="nav-link"
                ><i class="fas fa-cogs mr-2"></i>Configuration</router-link
              >
            </li>
            <!-- <li class="nav-item">
              <router-link :to="{ name: 'base.settings.index' }" class="nav-link"
                ><i class="fas fa-question-circle mr-2"></i>Assistance</router-link
              >
            </li> -->
          </ul>
        </div>
      </nav>
    </div>
    <div
      class="col-xl-2 navbar navbar-light navbar-expand-lg topnav-menu active justify-content-center"
    >
      <nav
        class="collapse navbar-collapse active justify-content-end"
        id="topnav-menu-content"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              :href="help_url"
              target="_blank"
              style="cursor:pointer"
              ><i class="fas fa-question-circle mr-2"></i>Assistance</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
